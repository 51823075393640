import React from 'react';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './Details.st.css';
import { AlignmentOptions, ISection, SectionTypes } from '../../types';
import Section from '../Section';
import { DetailsSectionViewModel } from '../../../../service-page-view-model/details-section-view-model/detailsSectionViewModel';
import { useGlobalAlignment } from '../useGlobalAlignment';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';

type DetailsProps = InjectedTranslateProps & {
  viewModel?: DetailsSectionViewModel;
  section: ISection;
  className?: string;
};

const DetailsComponent: React.FC<DetailsProps> = ({
  viewModel,
  section,
  className,
}) => {
  const settings = useSettings();
  const globalAlignment = useGlobalAlignment();
  const buttonAlignment =
    settings.get(settingsParams.detailsBoxAlignment) ===
    AlignmentOptions.STRETCH
      ? settings.get(settingsParams.detailsButtonAlignment)
      : settings.get(settingsParams.detailsBoxAlignment);
  const textAlignment =
    (settings.get(settingsParams.detailsBoxAlignment) !==
    AlignmentOptions.STRETCH
      ? settings.get(settingsParams.detailsBoxAlignment)
      : settings.get(settingsParams.detailsTextAlignment)) || globalAlignment;
  const boxWidth =
    settings.get(settingsParams.detailsBoxAlignment) ===
    AlignmentOptions.STRETCH
      ? '100%'
      : undefined;

  return (
    <Section
      sectionType={SectionTypes.DETAILS}
      withBookButton={section.bookButton}
      buttonAlignment={buttonAlignment}
      childrenAlignment={settings.get(settingsParams.detailsBoxAlignment)}
    >
      <div
        style={{ width: boxWidth }}
        className={st(classes.root, { textAlignment }, className)}
        data-hook="details-wrapper"
      >
        {settings.get(settingsParams.detailsDaysVisibility) &&
          viewModel?.daysOffered && (
            <Text className={classes.box} data-hook="details-days-offered">
              {viewModel?.daysOffered}
            </Text>
          )}
        {settings.get(settingsParams.detailsDurationVisibility) &&
          viewModel?.duration && (
            <Text className={classes.box} data-hook="details-duration">
              {viewModel.duration}
            </Text>
          )}
        {settings.get(settingsParams.detailsPriceVisibility) &&
          viewModel?.price && (
            <Text className={classes.box} data-hook="details-price">
              {viewModel.price}
            </Text>
          )}
        {settings.get(settingsParams.detailsLocationVisibility) &&
          viewModel?.location && (
            <Text className={classes.box} data-hook="details-location">
              {viewModel.location}
            </Text>
          )}
      </div>
    </Section>
  );
};

export default translate()(DetailsComponent);
