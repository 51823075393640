import React from 'react';
import {
  BILogger,
  IOwnerBILoggerContext,
  IVisitorBILoggerContext,
} from 'yoshi-flow-editor-runtime';

export enum LoggerTypes {
  SETTINGS = 'SETTINGS',
  WIDGET = 'WIDGET',
}

export const OwnerBIContext = React.createContext<IOwnerBILoggerContext | null>(
  null,
);

export const VisitorBIContext = React.createContext<IVisitorBILoggerContext | null>(
  null,
);

const BILoggerProvider = (loggerType: LoggerTypes) => <
  WrappedComponentProps extends any
>({
  children,
}: {
  children: React.ReactElement<WrappedComponentProps>;
}) => {
  return (
    // <BILoggerDefaults defaults={biDefaults}>
    <BILogger
      owner={loggerType === LoggerTypes.SETTINGS}
      visitor={loggerType === LoggerTypes.WIDGET}
    >
      {(logger: IOwnerBILoggerContext | IVisitorBILoggerContext) => {
        return loggerType === LoggerTypes.SETTINGS ? (
          <OwnerBIContext.Provider value={logger as IOwnerBILoggerContext}>
            {children}
          </OwnerBIContext.Provider>
        ) : (
          <VisitorBIContext.Provider value={logger as IVisitorBILoggerContext}>
            {children}
          </VisitorBIContext.Provider>
        );
      }}
    </BILogger>
    // </BILoggerDefaults>
  );
};

export const SettingsBILoggerProvider = BILoggerProvider(LoggerTypes.SETTINGS);
export const WidgetBILoggerProvider = BILoggerProvider(LoggerTypes.WIDGET);

export const useOwnerBILogger = (): IOwnerBILoggerContext =>
  React.useContext(OwnerBIContext) as IOwnerBILoggerContext;

export const useVisitorBILogger = (): IVisitorBILoggerContext =>
  React.useContext(VisitorBIContext) as IVisitorBILoggerContext;
